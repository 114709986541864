@import '../../styles/utils.scss';
@import '../../styles/componentShared.scss';

.el {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;

  @include tablet {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .right {
    background: $color-blue-10;
  }

  .content {
    width: 100%;
    display: flex;

    @include desktop {
      flex-direction: column;
      // padding: 0 $mobile-padding;
      // margin-top: 20px;
      height: 100%;
    }

    &.noImage {
      .wrapper {
        width: 100%;
        background: $color-blue;
        padding: 30px 20px;

        @include tablet {
          // height: 100%;
        }

        .title,
        .date {
          color: white;
        }

        .cta {
          a {
            color: white;
          }
        }
      }
    }

    &:hover {
      .wrapper {
        .image {
          img {
            transform: translate(-50%, -50%) scale(1.1);
          }

          &Wrap {
            width: calc(100% - 40px);

            @include desktop {
              width: 100%;
              height: calc(100% - 40px);
            }
          }
        }

        .cta {
          a {
            span {
              transform: translateX(20px);
            }
          }
        }
      }
    }

    .wrapper {
      width: 50%;
      height: 315px;
      background: #F7F7F7;

      @include desktop {
        width: 100%;
        height: inherit;
      }

      &:not(:first-child) {
        padding: 23px 20px;
      }

      .image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transform: translate3d(0, 0, 0);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s;
          pointer-events: none;
        }

        &:before {
          content: ' ';
          display: inline-block;
          width: 100%;
          padding-top: 315px;

          @include desktop {
            padding-top: 74.6%;
          }
        }

        &Wrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          transition: all 0.3s;
        }
      }

      .content {
        .name {
          font-weight: 600;
          font-size: 21px;
          line-height: 1.2;
          letter-spacing: -0.03em;
          color: $color-blue;
          margin-top: 15px;

          @include mobile {
            font-size: 20px;
            line-height: 1.1;
          }
        }

        .title {
          font-size: 18px;
          line-height: 1.3;
          letter-spacing: -0.03em;
          color: $color-blue;

          @include mobile {
            font-size: 14px;
          }
        }
      }

      .header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
      }

      .title {
        color: $color-blue;

        .description {
          @extend .globalCardTitle;
        }

      }

      .type {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.05em;
        text-align: left;
        background-color: $color-blue;
        padding: 3px 5px;
        color: white;
        text-transform: uppercase;
        margin-right: 15px;

        &.orange {
          background-color: $color-orange;
        }

        &:empty {
          display: none;
        }
      }

      .date {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.05em;
        text-align: left;
        color: black;
        margin: 5px 0;
        text-transform: uppercase;
      }

      .cta {
        margin-top: 40px;

        a {
          color: $color-orange;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.05em;
          text-decoration: none;
          position: relative;
          display: inline-flex;
          align-items: center;
          transition: .3s;

          span {
            transition: .3s;
            font-size: 24px;
            margin-bottom: -2px;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
