@import '../../styles/utils.scss';
@import '../../styles/componentShared.scss';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  max-width: $container-md;
  padding-top: var(--section-margin-standard);
  overflow: hidden;
  border-bottom: 1px solid $color-blue-25;

  .slickArrows {
    grid-row: 4;
    grid-column: 1;

    @extend .slickArrowsStandard;
  }

  .title {
    grid-row: 1;
    grid-column: 1 / 3;

    margin-bottom: 30px;
  }

  .secondaryText {
    grid-row: 2;
    grid-column: 1 / 3;

    font-size: 18px;
    line-height: 1.3;
    letter-spacing: -0.03em;
    @include mobile {
      margin-bottom: 30px;
    }
  }

  .wrapper {
    grid-row: 3;
    grid-column: 1 / 3;

    display: block;
    padding: 30px 0;
    
    @include mobile {
      padding-top: 0;
    }

    /*:global(.slick-slider) {
      position: static;
      max-width: $container-md;
      overflow: visible;
    }

    :global(.slick-list) {
      overflow: visible;
    }*/

    /*:global(.regular.slider) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }*/

    /*:global(.slick-track) {
      display: flex;
    }*/
  }

  .cta,
  .ctaCallback {
    grid-row: 4;
    grid-column: 2;

    display: flex;
    justify-content: flex-end;

    .ctaLink {
      padding: 20px 0 20px 40px;
      border-left: 1px solid $color-blue-25;
    }

    a {
      color: $color-orange;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
