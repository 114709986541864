@import '../../styles/utils.scss';
@import '../../styles/componentShared.scss';

.el {
  padding-top: 40px;
  width: 100%;
  overflow: hidden;
  max-width: $container-fhd;
  margin: 0 auto;

  @include tablet {
    margin-top: 40px;
    padding-top: 0;
  }

  .flexView {
    display: inline-grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  
  .reportCard {
    margin-bottom: 40px;
    flex-grow: 1;

    @include tablet {
      width: 100%;
      height: 100%;
    }

    @include mobile {
      margin-bottom: 0;
      height: 100%;
    }
  }
}

.carouselOverrides {
  :global(.slick-slide > div) {
    margin: 0 10px 0 0;
  }
  @include tablet {
    :global(.slick-slide > div) {
      margin: 0 2px 0 0;
    }
  }
  /*:global(.slick-slide > div) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include tablet {
      display: block;
    }
  }*/
  /*:global(.slick-slide + .slick-slide) {
    margin-left: 30px;
  }*/
  /*:global(.slick-slide) {
    width: 48.75%;
    @include tablet {
      height: auto;
      padding-right: 20px;
      & > div {
        height: 100%;
      }
    }
  }*/

  /*:global(.slick-arrow) {
    font-size: 24px;
    color: $color-orange;
    padding: 10px 8px 6px;
    cursor: pointer;
    position: absolute;
    top: -40px;
    right: 0;
    span {
      display: block;
    }
    span:before {
      display: block;
    }
    &:last-child {
      border-left: 1px solid $color-blue-25;
    }
    @include tablet {
      margin-top: -20px;
    }
  }
  :global(.slick-prev) {
    right: 40px;
    span:before {
      transform: scaleX(-1);
    }
  }
  :global(.slick-disabled) {
    cursor: default;
    span {
      opacity: 0.2;
    }
  }*/

  /*
  :global(.slick-slider) {
    width: 91.74%;
  }
  :global(.slick-list) {
    overflow: visible;
  }
  :global(.slick-slide > div) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include tablet {
      display: block;
    }
  }
  :global(.slick-slide + .slick-slide) {
    margin-left: 30px;
  }
  :global(.slick-slide) {
    width: 48.75%;
    @include tablet {
      height: auto;
      padding-right: 20px;
      & > div {
        height: 100%;
      }
    }
  }
  :global(.regular.slider) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  :global(.slick-track) {
    display: flex;
  }
  */
}
