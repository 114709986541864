@import '../../styles/utils.scss';

.el {
  transition: background 0.3s;
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  &:hover{
    .image{
      img {
        transform: scale(1.1);
      }
    }

      .cta{
        a{
          span{
            transform: translateX(20px);
          }
        }
      }

  }
  @include desktop {
    flex-direction: column;
    justify-content: stretch;
  }
  @include tablet {
    width: 100%;
    height: 100%;
  }
  .content {
    width: 50%;
    padding: 23px 20px;
    background: $color-grey;
    display: flex;
    flex-direction: column;

    @include desktop {
      width: 100%;
      flex-grow: 2;
      padding: 20px 15px;
    }
    .tag {
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    .tagBox {
      display: inline-block;
      padding: 3px 5px;
      margin-right: 9px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: #fff;
      background: $color-orange;
    }
    .date {
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
    }
    .title {
      margin-bottom: 40px;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      h4 {
        transition: color 0.3s;
        @include tablet {
          line-height: 1.1;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
    .cta {
      a {
        color: $color-orange;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.2;
        text-transform: uppercase;
      }
    }
    .tags {
      margin-top: auto;
      .tag {
        text-transform: uppercase;
        background-color: $color-blue-80;
        color: $color-white;
        font-size: 14px;
        padding: 5px 3px;
        margin-right: 10px;
        margin-top: 10px;
        display: inline-block;
        font-weight: 700;
        margin-bottom: 16px;
        line-height: 1.2;
      }
    }
  }
  .image {
    width: 50%;
    font-size: 0;
    line-height: 0;
    position: relative;
    min-height: 315px;
    overflow: hidden;
    @include desktop {
      width: 100%;
      &:after {
        content: ' ';
        display: block;
        width: 100%;
        padding-top: 74.28%;
      }
      min-height: inherit;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      object-fit: cover;
      object-position: center center;
      transition: 0.5s;
      @include desktop {

      }
    }
  }
  &.noImage {
    &:hover{
      .content{
        // border-bottom: 40px solid #fff;
      }
    }
    .content {
      width: 100%;
      background: $color-blue;
      color: #fff;
      transition:.4s;
      border-bottom: 0px solid #fff;
      .cta {
        a {
          color: #fff;
        }
      }
    }
  }
}
